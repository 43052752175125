<template>
  <div>
    <tab-bar-wrapper></tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :query="query"
        :filter="filter"
        :columns="columns"
        :componentName="$options.name"
        @ready="ready"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
      :commentList="commentList"
    />
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import parser from '@/api/parser.js'
import grid from '../../components/mixins/grid'
import { GET_ITEMS } from '@/api/graphql/Constants/Inventory'
import { COLUMNS__INVENTORY_HISTORY } from '@/lib/agGridColumnConfiguration'
import { ViewPurchaseTransaction, ViewSaleTransaction } from '@/lib/routerHelper'
import { CONTEXT_MENU__INVENTORY_HISTORY } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__INVENTORY_HISTORY } from '@/lib/agGridKbShortCuts'
export default {
  name: 'history',
  mixins: [grid],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper
  },
  data () {
    return {
      commentList: {
        'inventory_comment': {
          name: 'inventory_comment',
          label: 'Internal',
          text: '',
          editable: false,
          show: true
        },
        'system_logs': {
          name: 'system_logs',
          label: 'System',
          text: '',
          editable: false,
          show: true
        },
        'condition_comment': {
          name: 'condition_comment',
          label: 'Condition',
          text: '',
          editable: false,
          show: true
        },
        'id': false
      },

      contextMenuItems: (params) => CONTEXT_MENU__INVENTORY_HISTORY(params, this.user.initials),
      // query: columns => GRID__GET_ITEMS(columns),
      query: GET_ITEMS,
      filter: [{ key: 'receive_status__isnull', value: true }],

      columns: []
    }
  },
  methods: {
    viewPT (params) {
      ViewPurchaseTransaction({ id: params.node.data.id })
    },
    viewST (params) {
      ViewSaleTransaction({ id: params.node.data.id })
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__INVENTORY_HISTORY(this.user.initials), [])
    },

    contextMenuCallback (params) {
      if (params.dataKey) this[params.dataKey] = params.data
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    // parsing functions
    parseSelected (params) {
      parser.parseDrives(params)
    },

    parseFromDB (params) {
      parser.parseFromDB(params)
    },

    testEdit (params, dataType) {
      parser.parse(params, dataType)
    }
  },
  beforeMount () {
    this.columns = COLUMNS__INVENTORY_HISTORY
  }
}
</script>
