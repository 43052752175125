// module for loading fake data

import drives from './drives.json'
import services from './services.json'

var bsHistory = [
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' },
  { purchasedDate: '12/1/21', vendorID: 'ssTech', qty: '8', unitCost: '800', ptID: '12345', ptRep: 'GLL', status: 'Booked', cond: 'REF', clientID: 'FLSHP', salePrice: '1000', stRep: 'JTL', stID: '54321', salesDate: '12/20/22', rStatus: 'Received' }
]

var users = [
  { username: 'alittler', password: 'temptemp', role: 'admin', firstName: 'andrew', lastName: 'littler', email: 'andrew.littler@traqsys.com' },
  { username: 'jlittler', password: 'temptemp1', role: 'tech', firstName: 'joe', lastName: 'littler', email: 'joe.littler@traqsys.com' },
  { username: 'glittler', password: 'password', role: 'accountant', firstName: 'glen', lastName: 'littler', email: 'glen.littler@igsmn.com' }
]

const login = function (data) {
  const username = data.username
  const password = data.password

  for (var user in users) {
    if (username === users[user].username && password === users[user].password) {
      return true
    }
  }

  return false
}

const getDrives = function () {
  return drives
}

const getServices = function () {
  return services
}

const getDriverow = function (index) {
  var row
  for (var i = 0; i < drives.length; i++) {
    if (drives[i].Itemnumber === index) {
      row = drives[i]
      return row
    } else {
      row = 'row not found'
    }
  }
  return row
}

const editRow = function (index, data, list) {
  for (var i = 0; i < list.length; i++) {
    var currentRow = list[i]
    var primaryKey = currentRow[Object.keys(currentRow)[0]]
    if (primaryKey === index) {
      for (var j = 3; j < Object.keys(currentRow).length - 1; j++) {
        var columnName = Object.keys(currentRow)[j]
        if (currentRow[columnName] === null) {
          currentRow[columnName] = data[columnName].parsed
          currentRow.Description = currentRow.Description.replace(data[columnName].found, '')
          if (data[columnName].found === false) {
            currentRow.has_false = true
          }
        }
      }
      if (currentRow.has_false !== true) {
        currentRow.has_false = false
        currentRow.Description = ''
      }
    }
  }
}

export default {
  bsHistory: bsHistory,
  users: users,
  login: login,
  getDrives: getDrives,
  getServices: getServices,
  getDriverow: getDriverow,
  editRow: editRow
}
