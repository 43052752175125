// This component is used to parse the messy data from current customer's inventory lists and format them in a way that is consistent with our schema.

import mocker from '@/fserver/fserver'
// import api from '@/api'
import drives from '@/fserver/drives.json'

const parse = function (node, dataType) {
  // create a data object replicated the structure of the columns in the data being handled
  const dType = getDataList(dataType)
  const data = []
  const falses = []
  for (const column in driveColumns) {
    const found = driveColumns[column].func(node.data.Description)
    data[driveColumns[column].column] = found
    if (found.found === false) {
      falses[node.data.Itemnumber] += column
      falses[node.data.Itemnumber] = falses[node.data.Itemnumber].replace('undefined', '')
    }
  }
  mocker.editRow(node.data[Object.keys(node.data)[0]], data, dType)
}

const getDataList = function (dataType) {
  for (var type in dataList) {
    if (dataList[type].name === dataType) {
      return dataList[type].list
    } else {
      break
    }
  }
}

const parseFromDB = function (node) {
  // var pn = node.data.Itemnumber
  // var url = api.endpoints.googleSearches + '?pn=' + pn
  // var list = getFalses(node)
  /* api.get(url)
    .then(response => {
      var desc = response.data[0].description
      var foundInDB = []
      for (var item in list) {
        let column = list[item].name
        for (let col in driveColumns) {
          if (column === driveColumns[col].column) {
            foundInDB.push({ list: driveColumns[col].func(desc) })
          }
        }
      }
      return foundInDB }) */
}

/* const getGoogled = function (partNumber) {
  const url = api.endpoints.googleSearches + '?pn=' + partNumber
  return new Promise(resolve => {
    api.get(url)
      .then(response => {
        resolve(response.data[0].description)
      })
  })
} */

/* const getFalses = function (node) {
  var nodeData = node.data
  var list = []
  for (var column in nodeData) {
    if (nodeData[column] === false) {
      list.push({ name: column }) // put that in the list
    }
  }
  return list
} */

const findOtherInfo = function (node) {
  // this function is used to submit a search to google and return in json format the results of the query ( used for getting data that is missing out of current dataset or descriptions )
  var item = node.data.Itemnumber // the part number of the part to search for

  var titleArray = {} // used to store all the resulting titles of the search
  // var description = '' // concatenated version of all found titles to store in table

  mocker.grabGoogleResults(item)
    .then(response => {
      const itemArray = response.data.items

      for (var i = 0; i < itemArray.length; i++) {
        titleArray[i] = itemArray[i].title
        // description += itemArray[i].title
      }
      // api.post(api.endpoints.googleSearches, { pn: item, description: description })
    }) // search google with the query and then loop through the results and store the titles in the array
}

// Hard Drive Parsing
// capacity is unique but the rest return an object of { found: 'what it found', parsed: 'what we want it to be' }, all are false when nothing is found

const grabCapacity = function (desc) {
  const match = /(\S+\d+GB)\b/ // regex of what we want to look for

  if (match.test(desc)) {
    var result = match.exec(desc)[0] // store the found stuff in a variable
    var match2 = /(\/)|(\.)|(-)/ // regex of a weird capacity expression
    const index = result.search(match2) // store index of weird expression
    var capacity
    var capacityAmount
    var capacityID
    if (index > -1) { // if a valid index was found ( means weird expression )
      var broken = { firstHalf: result.substring(0, index), secondHalf: result.substring(index + 1, result.length) } // breakup weird expression
      capacity = broken.firstHalf + broken.secondHalf.replace(/\d+/, '')
      capacityAmount = broken.firstHalf
      capacityID = broken.secondHalf.replace(/\d+/, '')
    } else { // if not a weird expression do normal stuff
      capacity = result
      capacityAmount = result.replace(/\D+/, '')
      capacityID = result.replace(/\d+/, '')
    }

    return { cap: capacity, capa: capacityAmount, capId: capacityID, found: result }
  } else {
    return { cap: false, capa: false, capId: false, found: false }
  }
}

const grabInterface = function (desc) {
  const match = /(SAS)|(SATA)|(NVME)|(ULTRA160 SCSI)|(ULTRA320 SCSI)|(SE SCSI)|(LVD SCSI)|(LVD\/SE SCSI)|(DIFF\/HVD SCSI)|(IDE)|(PATA)|(SCSI)|(FC)|(SSA)|(FIBRE)|(FIBER)|(FIBBRE)|(FIBBER)/ // regex of seen descriptiors so far + what is in traq db
  const variations = /(FIBRE)|(FIBBRE)|(FC)|(FIBER)/ // seen mispellings so far
  var found
  var parsed

  if (match.test(desc)) {
    found = match.exec(desc)[0] // store matched expression
    if (variations.test(found)) {
      parsed = 'FIBER CHANNEL' // return wanted expression for fiber
    } else {
      parsed = found // return wanted expression for others
    }
  } else {
    parsed = false
    found = false
  }
  var results = { found: found, parsed: parsed }
  return results
}

const grabFormFactor = function (desc) {
  // grab 2.5/sff, 3.5, etc
  const match = /(SFF)|(LFF)|(2.5")|(2.5'')|(2.5)|(3.5")|(3.5'')|(3.5)|(2.5in)|(2.5 in)|(3.5in)|(3.5 in)/ // expressions for form factor
  const sff = /(SFF)|(2.5")|(2.5'')|(2.5)|(2.5in)|(2.5 in)/ // sff variations
  var found
  var parsed

  if (match.test(desc)) {
    found = match.exec(desc)[0]
    if (sff.test(found)) {
      parsed = '2.5"'
    } else {
      parsed = '3.5"'
    }
  } else {
    found = false
    parsed = false
  }
  var results = { found: found, parsed: parsed }
  return results
}

const grabSpindleSpeed = function (desc) {
  // grab rpm or k and turn into shortened + k
  const match = /(((1(0|5))|((54|72)00)|((7.2|5.4)))((krpm)|(k\srpm)|RPM|K|(\sRPM)))|((5400)|(5.2)|(7200)|(7.2))/i // expressions for spindle speed found
  const badExpression = /(5400)|(7200)|(RPM)/i // not normalized
  var found
  var parsed

  if (match.test(desc)) {
    found = match.exec(desc)[0]
    if (badExpression.test(found)) {
      if (/(5400)/.test(found)) {
        parsed = '5.4K'
      } else if (/(7200)/.test(found)) {
        parsed = '7.2K'
      } else if (/(((5.4)|(7.2))((\srpm)|(rpm)))/i.test(found)) {
        var hold = found.replace(' ', '')
        parsed = hold.replace('RPM', '') + 'K'
      } else {
        parsed = found.replace('RPM', '')
      }
    } else {
      parsed = found
    }
  } else {
    parsed = false
    found = false
  }
  var results = { found: found, parsed: parsed }
  return results
}

const grabThroughput = function (desc) {
  const match = /(\d+GBPS)|(\d+G)\b|(\d.\dG)\b|\b(\d{1}GB)\b|(\d+GBP\SS)|(\d+GBS)/ // throughput variations
  var found
  var parsed

  if (match.test(desc)) {
    found = match.exec(desc)[0]
    if (!/(\d+GBPS)/.test(found)) {
      parsed = /(\d*)/.exec(found)[0] + 'GBPS'
    }
  } else {
    parsed = false
    found = false
  }
  var results = { found: found, parsed: parsed }
  return results
}

const grabSubType = function (desc) {
  // grab ssd hdd tape, etc
  const match = /(SSD)|(HDD)|(TAPE)|(hard drive)|(disk drive)|(hd)/i
  const hdd = /(HDD)|(hard drive)|(disk drive)|(hd)/i
  var found
  var parsed

  if (match.test(desc)) {
    found = match.exec(desc)[0]
    if (hdd.test(found)) {
      parsed = 'HDD'
    } else {
      parsed = found
    }
  } else {
    found = false
    parsed = false
  }
  var results = { found: found, parsed: parsed }
  return results
}

const driveColumns = [
  {
    column: 'capacity',
    func: params => {
      const cap = grabCapacity(params).cap
      return { found: cap, parsed: cap }
    }
  },
  {
    column: 'capacityamount',
    func: params => {
      const cap = grabCapacity(params).capa
      return { found: cap, parsed: cap }
    }
  },
  {
    column: 'capacityunit_id',
    func: params => {
      const cap = grabCapacity(params).capId
      return { found: cap, parsed: cap }
    }
  },
  {
    column: 'interface_id',
    func: params => {
      return grabInterface(params)
    }
  },
  {
    column: 'physicalsize_id',
    func: params => {
      return grabFormFactor(params)
    }
  },
  {
    column: 'speed_id',
    func: params => {
      return grabThroughput(params)
    }
  },
  {
    column: 'spindlespeed_id',
    func: params => {
      return grabSpindleSpeed(params)
    }
  },
  {
    column: 'subtype_id',
    func: params => {
      return grabSubType(params)
    }
  }
]

const dataList = [
  { name: 'drives', list: drives }
]

export default {
  findOtherInfo: findOtherInfo,
  parseFromDB: parseFromDB,
  parse: parse
}
